html,
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  font-family: 'Amiri-Bold', serif;
  overflow-y: auto;
  overflow-x: hidden;
}

.ani-cute {
  cursor: pointer;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-duration: 0.5s;
  &:hover {
    transform: scale(1.1);
  }
}

a {
  text-decoration: none;
}
